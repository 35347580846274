import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [];

export const dictionary = {
		"/(sigin-in)": [10],
		"/approve-hotel-review/[id]/[approver]": [11],
		"/covid-hub": [12],
		"/(authenticated)/credits": [3,[2]],
		"/(authenticated)/dashboard": [4,[2]],
		"/hotel-review/[id]": [13],
		"/(authenticated)/itinerary/[bookingRef]/[passengerRef]": [5,[2]],
		"/(authenticated)/people": [6,[2]],
		"/(authenticated)/people/[profileId]": [7,[2]],
		"/(authenticated)/risk-hub": [8,[2]],
		"/sso": [14],
		"/(authenticated)/track": [9,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';