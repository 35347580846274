/* eslint-disable prettier/prettier */
import { signedInUser } from '@ctm/auth/store';

/*globals pendo */

const internalDomains = new Set(['travelctm.com', 'travelqac.com', 'ctmportal.com', 'mailinator.com', 'local', 'example.com'])
function isInternalUser(email) {
    const [localpart, domain] = email.split('@');
    if (internalDomains.has(domain)) {
        return true;
    }
    if (localpart.toLowerCase().startsWith('test') && localpart.includes('.')) {
        return true;
    }
    return false;
}

export function setupPendo(apiKey) {
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');

    let initialized = false;
	signedInUser.subscribe(async (user) => {
        if (initialized || !user) {
            return;
        }
        
        // This function creates visitors and accounts in Pendo
        // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
        // Please use Strings, Numbers, or Bools for value types.
        pendo?.initialize({
            visitor: {
                id: user.email,          // Required if user is logged in
                email: user.email,       // Recommended if using Pendo Feedback, or NPS Email
                // full_name: user.name,    // Recommended if using Pendo Feedback
                internal: isInternalUser(user.email),
                role: user.roleTypeName.toLowerCase(), // Optional

                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
            },

            account: {
                id: user.debtor,       // Highly recommended, required if using Pendo Feedback
                name: user.debtorName, // Optional
                // is_paying:          // Recommended if using Pendo Feedback
                // monthly_value:      // Recommended if using Pendo Feedback
                // planLevel:          // Optional
                // planPrice:          // Optional
                // creationDate:       // Optional

                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            }
        });
	});
}
